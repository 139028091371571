/**
 * Created by jerry on 2020/04/03.
 * 通用次卡销售提成设置经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取组织架构
    getEntity: params => {
        return API.POST('api/entity/list', params)
    },
    // 获取通用次卡销售组织单位提成方案列表
    getSaleGeneralCardCommissionScheme: params => {
        return API.POST('api/saleGeneralCardCommissionScheme/list', params)
    },
    // 获取通用次卡销售分类提佣方案
    getSaleGeneralCardCategoryCommission: params => {
        return API.POST('api/saleGeneralCardCategoryCommission/all', params)
    },
    // 获取通用次卡销售分类经手人提佣方案
    getSaleGeneralCardCategoryHandlerCommission: params => {
        return API.POST('api/saleGeneralCardCategoryHandlerCommission/all', params)
    },
    // 获取分类下的通用次卡提佣方案
    getSaleGeneralCardCommission: params => {
        return API.POST('api/saleGeneralCardCommission/all', params)
    },
    // 获取通用次卡下经手人提佣方案
    getSaleGeneralCardHanderCommission: params => {
        return API.POST('api/saleGeneralCardHandlerCommission/all', params)
    },
    // 保存通用次卡销售分类经手人提成方案
    updateSaleGeneralCardCategoryHandlerCommission: params => {
        return API.POST('api/saleGeneralCardCategoryHandlerCommission/update', params)
    },
    // 保存通用次卡销售分类提成方案
    updateSaleGeneralCardCategoryCommission: params => {
        return API.POST('api/saleGeneralCardCategoryCommission/update', params)
    },
    // 保存通用次卡销售提成方案
    updateSaleGeneralCardCommission: params => {
        return API.POST('api/saleGeneralCardCommission/update', params)
    },
    // 保存通用次卡下经手人提成方案
    updateSaleGeneralCardHanderCommission: params => {
        return API.POST('api/saleGeneralCardHandlerCommission/update', params)
    },
    // 创建通用次卡销售组织单位提成方案
    createSaleGeneralCardCommissionScheme: params => {
        return API.POST('api/saleGeneralCardCommissionScheme/create', params)
    },
    // 删除通用次卡销售提成方案
    deleteSaleGeneralCardCommissionScheme: params => {
        return API.POST('api/saleGeneralCardCommissionScheme/delete', params)
    },
    // 获取所有通用次卡经手人提佣方案
    getAllSaleGeneralCardSchemeHanderCommission: params => {
        return API.POST('api/saleGeneralCardSchemeHandlerCommission/all', params)
    },
    // 保存所有通用次卡经手人提成方案
    updateSaleGeneralCardSchemeHandlerCommission: params => {
        return API.POST('api/saleGeneralCardSchemeHandlerCommission/update', params)
    },
    // 33.8.获取套餐卡通用次卡销售分类经手人提佣方案
    getPackageSaleGeneralCardCategoryHandlerCommission: params => {
        return API.POST('api/saleGeneralCardCategoryHandlerCommission/packageCard', params)
    },
    // 33.13.获取套餐卡通用次卡下经手人提佣方案
    getPackageSaleGeneralCardHandlerCommission: params => {
        return API.POST('api/saleGeneralCardHandlerCommission/packageCard', params)
    },
    // 33.16.获取所有套餐卡通用次卡经手人提佣方案
    getPackageSaleGeneralCardSchemeHandlerCommission: params => {
        return API.POST('api/saleGeneralCardSchemeHandlerCommission/packageCard', params)
    },
}